import * as React from "react"
import { Script } from "gatsby"
import { useIntl } from "gatsby-plugin-intl-v4"

import "./planCopyWebsite.css"
import * as styles from "./plan.module.css"
import buildingImg from '../images/plan/building.png'


const Plan = () => {
  const intl = useIntl()

  return (
    <section className="plans section" style={{ backgroundColor: "#F5F2EE" }}>
      <div className={styles.titles}>
        <h3 className={styles.title}>{intl.formatMessage({ id: 'floorPlan.title' })}</h3>
        <h4 className={styles.subtitle}>{intl.formatMessage({ id: 'floorPlan.subtitle' })}</h4>
      </div>
      <div className="plans-ext-div">
        <div className="plans-building">
          <div id="building" className={`building horizontal ${styles.buildingMore}`} data-img-w="1080" data-img-h="1080">
            <img src={buildingImg} alt="Mansfield" />
            <svg version="1.1" x="0px" y="0px" width="1080px" height="1080px" viewBox="0 0 1080 1080" >
              <g id="floor2" className="floor"><path d="M672,951c34-1,57-2,94,1l195,10v-79l-195-20c-36-4-60-3-94-1l-563,26v5h1v53h-1v19L672,951z"></path></g>
              <g id="floor3" className="floor"><path d="M766,814v-6c-34-6-57-4-94-2l-335,20v7l-228,13v5l2,1v30h-1v1h-1v5l563-26c34-2,58-3,94,1l195,20v-9l-41-5v-11l-18-2v3h-5v-20l3-1v-6C900,831,766,814,766,814z"></path></g>
              <g id="floor4" className="floor"><path d="M109,803v5l2,2v30h-1v1h-1v5l228-13v-7l335-20c37-2,60-4,94,2v6c0,0,134,17,134,18v-5c0-1-3-2-3-2v-32l3-1c0,0,0-5,0-5c0-2-107-18-107-18c-38-7-50-6-96-3l-126,9c0,0-12-4-68,0L109,803z"></path></g>
              <g id="floor5" className="floor"><path d="M900,741c0-1-107-21-107-21c-41-8-50-6-96-3l-125,11v-6c0,0-13-6-69,0l-395,33v10l3,2v30h-1v1h-1v5l394-28c56-4,68,0,68,0l126-9c46-3,58-4,96,3c0,0,107,16,107,18v-5c0-1-3-2-3-2v-32l3-1C900,747,900,741,900,741z"></path></g>
              <g id="floor6" className="floor"><path d="M108,755l395-33c56-6,69,0,69,0v6l125-11c46-3,55-5,96,3c0,0,107,20,107,21v-5c0-1-3-2-3-2v-31l3-1c0,0,0-6,0-6c0-2-107-24-107-24c-39-9-43-9-96-4l-473,45c0,0-31,3-31,5v5l3,1v11l-84,7v11h-4V755z"></path></g>
              <g id="floor7" className="floor"><path d="M193,718c0-2,31-5,31-5l473-45c53-5,57-5,96,4c0,0,107,22,107,24v-5c0-1-3-2-3-2v-31l3-1c0,0,0-6,0-6c0-2-107-28-107-28c-35-9-43-9-96-4l-473,51c0,0-31,3-31,5v5l3,2v29c0,0-3,1-3,2V718z"></path></g>
              <g id="floor8" className="floor"><path d="M910,596c0-3-117-34-117-34c-34-11-37-12-96-5l-473,58c0,0-32,3-32,6v18l4,2v27c0,0-3,1-3,2v5c0-2,31-5,31-5l473-51c53-5,61-5,96,4c0,0,107,26,107,28v-5c0-1-3-2-3-2v-26c13-1,13-4,13-4S910,596,910,596z"></path></g>
              <g id="floor9" className="floor"><path d="M205,588v5c0,0,1,2,5,2v22l487-60c59-7,62-6,96,5l113,31v-27c3-1,3-3,3-3v-5c0-2-105.3-33.4-105-33c-36-12-46-13-97-5l-471,62C236,582,205,585,205,588z"></path></g>
              <g id="floor10" className="floor"><path d="M205,588c0-3,31-6,31-6l471-62c51-8,61-7,97,5c-0.3-0.4,105,31,105,33v-5c0-1-3-2-3-2v-30c3-1,3-3,3-3v-5c0-2-105.3-37.4-105-37c-37-13-46-13-97-5l-471,68c0,0-31,3-31,6v5c0,0,0,2,3,3v28c0,0-3,1-3,2V588z"></path></g>
              <g id="floor11" className="floor"><path d="M205,545v-5c0-1,3-2,3-2v-28c-3-1-3-2-3-2v-5c0-3,31-7,31-7l471-74c47-7,58-10,97,6c-0.2-0.3,105,38,105,39v6c0,0,0,2-3,3v29c0,0,3,1,3,3v5c0-2-105.3-37.4-105-37c-37-13-46-13-97-5l-471,68C236,539,205,542,205,545z"></path></g>
              <g id="floor12" className="floor"><path d="M909,467v-4c0-2-3-3-3-3v-29c3-1,3-3,3-3v-6c0-1-105.2-42.3-105-42c-41-18-53-14-97-6l-471,79c0,0-31,4-31,7v6c0,0,0,1,3,2v28c0,0-3,1-3,2v5c0-3,31-7,31-7l471-74c47-7,58-10,97,6C803.8,427.7,909,466,909,467z"></path></g>
              <g id="floor13" className="floor"><path d="M205,460v-5c0-1,3-2,3-2v-27c-3-1-3-2-3-2v-6c0-3,31-8,31-8l471-85c53-10,61-10,97,7c-0.2-0.3,105,43,105,45v6c0,0,0,2-3,3v29c0,0,3,1,3,3v4c0-1-105.2-42.3-105-42c-41-18-53-14-97-6l-471,79C236,453,205,457,205,460z"></path></g>
              <g id="floor14" className="floor"><path d="M205,418v-5c0-1,3-2,3-2v-27c-3-1-3-2-3-2v-6c0-3,31-9,31-9l471-91c58-11,61-9,97,8c-0.2-0.3,105,46,105,48v6c0,0,0,2-3,3v28c0,0,3,1,3,3v5c0-2-105.2-45.3-105-45c-36-17-44-17-97-7l-471,85C236,410,205,415,205,418z"></path></g>
              <g id="floor15" className="floor"><path d="M205,376v-5c0-1,3-3,3-3v-26c-3-1-3-3-3-3v-6c0-3,31-9,31-9l471-96c60-14,66-8,97,7c-0.2-0.3,105,50,105,52v6c0,0,0,2-3,3v28c0,0,3,1,3,3v5c0-2-105.2-48.3-105-48c-36-17-39-19-97-8l-471,91C236,367,205,373,205,376z"></path></g>
              <g id="floor16" className="floor"><path d="M205,333v-4c0-2,3-3,3-3v-31c-3-1-3-3-3-3v-5c0-4,31-10,31-10l471-103c65-16,67-6,97,8c-0.2-0.3,105,53,105,55v6c0,0,0,3-3,4v32c0,0,3,1,3,4v4c0-2-105.2-52.3-105-52c-31-15-37-21-97-7l-471,96C236,324,205,330,205,333z"></path></g>
              <g id="floor17" className="floor"><path d="M205,287v-4c0-3,3-4,3-4v-30c-3-1-3-3-3-3v-5c0-5,31-11,31-11l471-109c62-17,68-7,97,8c-0.2-0.3,105,56,105,58v6c0,0,0,3-3,4v32c0,0,3,1,3,4v4c0-2-105.2-55.3-105-55c-30-14-32-24-97-8L236,277C236,277,205,283,205,287z"></path></g>
              <g id="floor18" className="floor"><path d="M205,241v-5c0-3,3-4,3-4v-31c-3-1-3-3-3-3v-5c0-5,31-11,31-11L707,66c64-18,64-8,97,9c-0.2-0.3,105,59,105,62v5c0,0,0,3-3,5v32c0,0,3,2,3,4v4c0-2-105.2-58.3-105-58c-29-15-35-25-97-8L236,230C236,230,205,236,205,241z"></path></g>
            </svg>
          </div>
        </div>
        <div className="plans-selectors">
          <form name="plan_selector" method="post">
            <input name="property" value="mansfield" type="hidden" />
            <input name="unit_name" value="609" type="hidden" />
            <input name="lang" value="fr" type="hidden" />
            <div className="plans-type-selector"><h3>{intl.formatMessage({ id: 'floorPlan.select' })}<br />{intl.formatMessage({ id: 'floorPlan.unitType' })}</h3>
              <div>
                <label className="checkbox">
                  <input name="unit_type[]" value="studio" type="checkbox" />
                  {intl.formatMessage({ id: 'floorPlan.studio' })}</label>
                <label className="checkbox">
                  <input name="unit_type[]" value="1 Bedroom" type="checkbox" />
                  {intl.formatMessage({ id: 'floorPlan.1bedroom' })}</label>
                <label className="checkbox">
                  <input name="unit_type[]" value="2 Bedrooms" type="checkbox" />
                  {intl.formatMessage({ id: 'floorPlan.2bedroom' })}</label>
                <label className="checkbox">
                  <input name="unit_type[]" value="3 Bedrooms" type="checkbox" />
                  {intl.formatMessage({ id: 'floorPlan.3bedroom' })}</label>
              </div>
            </div>
            <div className="plans-floor-selector"><h3>{intl.formatMessage({ id: 'floorPlan.select' })}<br />{intl.formatMessage({ id: 'floorPlan.floor' })}</h3>
              <div>
                <input value="2" className="bt-floor" type="button" />
                <input value="3" className="bt-floor" type="button" />
                <input value="4" className="bt-floor" type="button" />
                <input value="5" className="bt-floor" type="button" />
                <input value="6" className="bt-floor" type="button" />
                <input value="7" className="bt-floor" type="button" />
                <input value="8" className="bt-floor" type="button" />
                <input value="9" className="bt-floor" type="button" />
                <input value="10" className="bt-floor" type="button" />
                <input value="11" className="bt-floor" type="button" />
                <input value="12" className="bt-floor" type="button" />
                <input value="13" className="bt-floor" type="button" />
                <input value="14" className="bt-floor" type="button" />
                <input value="15" className="bt-floor" type="button" />
                <input value="16" className="bt-floor" type="button" />
                <input value="17" className="bt-floor" type="button" />
                <input value="18" className="bt-floor" type="button" />
              </div>
            </div>
            <div className="plans-unit-selector">
              <h3>{intl.formatMessage({ id: 'floorPlan.select' })}<br />{intl.formatMessage({ id: 'floorPlan.unit' })}</h3>
              <div>
                <div id="floor-plan">
                  <label className="select">
                    <select name="select_unit" className="select_unit">
                      <option disable="">Sélectionner une unité</option>
                      <option value="15">301</option>
                      <option value="16">302</option>
                      <option value="17">303</option>
                      <option value="18">304</option>
                      <option value="19">305</option>
                      <option value="20">306</option>
                      <option value="21">307</option>
                      <option value="22">308</option>
                      <option value="23">309</option>
                      <option value="24">310</option>
                      <option value="25">311</option>
                      <option value="26">312</option>
                      <option value="27">313</option>
                      <option value="28">314</option>
                      <option value="29">315</option>
                      <option value="30">316</option>
                      <option value="31">317</option>
                      <option value="32">318</option>
                      <option value="33">319</option>
                      <option value="34">320</option>
                      <option value="35">321</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="plans-unit"></div>
        <Script>{`
        url = "https://mansfieldcondos.com/";
        base = "https://mansfieldcondos.com/2022/";
        $window = $(window);
        lang = $("#language").html();
        url_lang = $("#language").html() + "/";
        floors_render = [];
        query_array = [];
        
        function initPlans() {
        
            var building_container = $('div.plans-building'),
                building = $('div.building'),
                building_w = building.data('img-w'),
                building_h = building.data('img-h'),
                building_ratio = building_w / building_h,
                property_key = $('input[name="property"]').val();
        
            var input_unit_type_val = [],
                input_unit_occupancy_date_val = '',
                act_unit = $('div.unit-title').data('act-unit') !== undefined ? $('div.unit-title').data('act-unit') : '',
                unit_name = $('input[name="unit_name"]').val();
        
            $('div.plans-floor-selector input').on('click', function (e) {
                e.preventDefault();
            });
        
            var input_unit_type = $('input[name="unit_type[]"]');
            //input_unit_occupancy_date = $('input[name="unit_occupancy_date"]');
        
            for (var i = 0; i < input_unit_type.length; i++) {
                var cb = input_unit_type.eq(i);
                if (cb.is(':checked')) {
                    input_unit_type_val.push(cb.val());
                }
            }
        
            input_unit_type.on('click', function () {
                var $this = $(this);
        
                input_unit_type_val = [];
        
                for (var i = 0; i < input_unit_type.length; i++) {
                    var cb = input_unit_type.eq(i);
                    if (cb.is(':checked')) {
                        input_unit_type_val.push(cb.val());
                    }
                }
        
                var act_floor = input_unit_type_val.length > 0 ? $('input.bt-floor.active').val() : '';
        
                var post = {
                    'action': 'select_floor',
                    'property': property_key,
                    'unit_type': input_unit_type_val,
                    'floor': act_floor,
                    'lang': lang,
                    'url_lang': url_lang
                }
        
                $.post(base + 'php/pms_ajax.php', post, function (response) {
                    if (response.success) {
                        $('div.plans-floor-selector').html(response.select_floor);
                        // $('div#building svg').remove();
                        // $('div#building').append(response.building);
                        setSelectFloor();
                        setTimeout(() => document.getElementsByClassName("plans-floor-selector")[0].scrollIntoView({ behavior: "smooth" }),300)
        
                        if (act_floor !== '') {
                            $('input.bt-floor[value="' + act_floor + '"]').click();
                        } //else {
                        $('div.plans-unit-selector').empty();
                        $('div.plans-unit').empty();
                        //}
                    }
                }, 'json');
            });
        
            if (typeof query_array !== 'undefined' && 'type' in query_array) {
                $('input[value="' + query_array['type'] + '"]').click();
            }
        
            setSelectFloor();
            setFloorPlan();
            setUnit();
        
            function setSelectFloor() {
                var input_bt_floor = $('input.bt-floor');
        
                input_bt_floor.off('click');
                input_bt_floor.on('click', function (e) {
                    e.preventDefault();
        
                    var $this = $(this),
                        val = $this.val();
        
                    input_bt_floor.removeClass('active');
                    $this.addClass('active');
        
                    var post = {
                        'action': 'select_unit',
                        'property': property_key,
                        'unit_type': input_unit_type_val,
                        'floor': val,
                        'unit': act_unit,
                        'unit_occupancy_date': input_unit_occupancy_date_val,
                        'floors_render': floors_render,
                        'img_building_dimensions': { 'width': building_w, 'height': building_h },
                        'lang': lang,
                        'url_lang': url_lang
                    }
        
                    //console.log(post);
        
                    $.post(base + 'php/pms_ajax.php', post, function (response) {
                        if (response.success) {
                            /* $('div#building svg').remove();
                            $('div#building').append(response.building); */
                            setBuilding();
                            $('div.plans-unit-selector').html(response.select_unit);
                            setFloorPlan();
        
                            $('div.plans-unit').empty();
                        }
                    }, 'json');
                });
        
                setBuilding();
            }
        
            function setBuilding() {
                $('div#building svg g.floor').off('click');
                $('div#building svg g.floor').on('click', function () {
                    var $this = $(this),
                        val = $this.attr('id').replace('floor', '');
        
                        $('div#building svg g.floor').removeClass("active");
                        $this.addClass("active");

                    $('input.bt-floor[value="' + val + '"]').click();
                });
            }
        
            function setFloorPlan() {
                if ($('div.plans-unit-selector g.units > g').length > 0) {
                    setTimeout(() => document.getElementsByClassName("plans-unit-selector")[0].scrollIntoView({ behavior: "smooth" }),300)
                }
        
                $('select[name="select_unit"]').on('change', function () {
                    var $this = $(this);
        
                    $('g[id="unit' + $this.val() + '"]').click();
                });
        
                $('div#floor-plan svg g.units > g').off('click');
                $('div#floor-plan svg g.units > g').on('click', function () {
                    var $this = $(this);
        
                    selectFloorPlan($this);
                });
            }
        
            function selectFloorPlan($this) {
                var val = $this.attr('id'),
                    act_unit = val.replace('unit', '');
        
                $('div#floor-plan svg g.units g').removeClass('active');
                $this.attr('class', $this.attr('class') + ' active');
        
                var post = {
                    'action': 'unit',
                    'unit': act_unit,
                    'lang': lang,
                    'url_lang': url_lang
                }
        
                $.post(base + 'php/pms_ajax.php', post, function (response) {
                    if (response.success) {
                        //console.log(response);
                        $('div.plans-unit').html(response.unit);
                        setUnit();
                    }
                }, 'json');
            }
        
            function setUnit() {
                var unit_details = $('div.unit-details ol'),
                    unit_images = $('div.unit-images img'),
                    bt_floors_nav = $('div.floors-nav > div');
        
                if ($('div.plans-unit > div').length > 0) {
                  setTimeout(() => document.getElementsByClassName("plans-unit")[0].scrollIntoView({ behavior: "smooth" }),300)
                }
        
                bt_floors_nav.on('click', function () {
                    var $this = $(this),
                        floor = $this.data('floor');
        
                    bt_floors_nav.removeClass('active');
                    $this.addClass('active');
        
                    unit_details.removeClass('show');
                    unit_images.removeClass('show');
        
                    $('div.unit-details ol.floor-' + floor).addClass('show');
                    $('div.unit-images img.img-' + floor).addClass('show');
                });
        
                bt_floors_nav.eq(0).click();
        
                $('div.share a').on('click', function (e) {
                    var $this = $(this),
                        url = $this.attr('href'),
                        title = $this.attr('title');
        
                    switch (title) {
                        case 'Facebook':
                            e.preventDefault();
                            window.open(url, 'facebook-share-dialog', "width=626,height=436");
                            break;
                        case 'Twitter':
                            e.preventDefault();
                            window.open(url, 'twitter-share-dialog', "width=626,height=436");
                            break;
                        case 'Mail':
                            e.preventDefault();
                            window.open(url, '_self');
                            break;
                    }
                });
            }
        }
        
        initPlans();
        `}</Script>
      </div>
    </section>
  )
}

export default Plan
