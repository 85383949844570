import * as React from "react"

import Header from "../header"
import Footer from "../footer"
import Plan from "../plan"
import CentralVideoIntroPlan from "../centralVideoIntroPlan"

import { useIntl } from "gatsby-plugin-intl-v4"

import { StaticImage } from "gatsby-plugin-image"

const FloorPlan = () => {

    const intl = useIntl();

    return (

        <div className={`pageContainer`}>
            <Header hidePlaceholder={true} />

            <CentralVideoIntroPlan />
            <Plan />
            <section className="footerSection section fp-auto-height" style={{ backgroundColor: "#d9d9d9" }}>
                <div>
                    <StaticImage
                        src='../../images/homepage/Mansfield_006_L.png'
                        loading="eager"
                        placeholder="blurred"
                        layout="fullWidth"
                        quality={80}
                        formats={["auto", "webp", "avif"]}
                        alt={intl.formatMessage({ id: 'alts.pool' })}
                        style={{
                            maxHeight: "35vh"
                        }}
                    />
                </div>
                <Footer variant={2} hideBorder={true} />
            </section>

        </div>
    )

}

export default FloorPlan
