import * as React from "react"


import Layout from "../components/layout"
import Seo from "../components/seo"
import FloorPlan from "../components/pages/FloorPlan"

import { useIntl } from "gatsby-plugin-intl-v4"

const FloorPlanPage = () => {

  const intl = useIntl()

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: 'h1.plans' })}
        description={intl.formatMessage({ id: 'seo.description' })}
        pageName={'plans'}
      />
      <FloorPlan />
    </Layout>
  )
}

export default FloorPlanPage
