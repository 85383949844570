import * as React from "react"
import { useIntl } from "gatsby-plugin-intl-v4"

import * as styles from "./centralVideoIntroPlan.module.css"

import ArrowDownSVG from '../assets/svg/double-caret-down.svg'

const CentralVideoIntroPlan = () => {

  const intl = useIntl();

  return (
    <section className={`${styles.section} section active`}>
      <div className={styles.bufferUp}></div>
      <div className={styles.flexColumn}>
        <div className={styles.main}>
          <div className={styles.top}>
            <p>{intl.formatMessage({ id: 'floorPlan.top' })}</p>
          </div>
          <div className={styles.bottom}>
            <p>{intl.formatMessage({ id: 'floorPlan.bottom' })}</p>
          </div>
          <div className={styles.video}>
            <iframe
              title="Timelapse of Montreal's sky 2 | Timelapse du ciel de Montréal 2"
              src="https://player.vimeo.com/video/725664449?background=1&autoplay=1&loop=1&byline=0&title=0&dnt=1"
              allowfullscreen style={{ border: "0", overflow: "hidden" }}></iframe>
          </div>
        </div>
      </div>
      <div className={styles.bufferUp}></div>
      <div className={styles.scrollDown}>
        <p>{intl.formatMessage({ id: 'navigation.scrollDown' })}</p>
        <ArrowDownSVG height={10} width={10} />
      </div>
    </section>
  )
}

export default CentralVideoIntroPlan
